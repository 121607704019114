import "./_components.js";
import "./_functions.js";
import "./functions/burger.js";
import { ItcCustomSelect } from "./components/ex.js";

document.addEventListener("DOMContentLoaded", () => {
  document.addEventListener("scroll", () => {
    const header = document.querySelector(".header");
    if (window.scrollY > 50) {
      header.classList.add("scroll");
    } else {
      header.classList.remove("scroll");
    }
  });

  const select1 = new ItcCustomSelect("#select-1");

  const scrollLinks = document.querySelectorAll('a[href^="#"]');
  scrollLinks.forEach((link) => {
    link.addEventListener("click", function (e) {
      e.preventDefault();
      const targetId = this.getAttribute("href").substring(1);
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        const offsetTop = targetElement.offsetTop - 200;
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      }
    });
  });

  const form = document.querySelector(".consult-form");
  const formError = document.getElementById("form-error");
  const formSuccess = document.querySelector(".consult-success");
  const formAgain = formSuccess.querySelector(".consult-form__submit");

  const categoryTranslation = {
    "forex-broker": "Форекс-брокер",
    casino: "Казино",
    cryptocurrencies: "Криптовалюты",
    "binary-options-broker": "Брокер бинарных опционов",
  };

  if (form) {
    form.addEventListener("submit", async (e) => {
      e.preventDefault();
      const formData = new FormData(e.target);

      const data = {
        fio: formData.get("fio"),
        phone: formData.get("phone"),
        category: categoryTranslation[select1.value],
        description: formData.get("description"),
      };

      if (!data.fio || !data.phone || !data.category || !data.description) {
        formError.style.display = "block";
        return;
      }

      formError.style.display = "none";
      const response = await fetch("send.php", {
        method: "POST",
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (result.success) {
        formSuccess.style.display = "block";
        form.style.display = "none";

        form.reset();

        select1.value = "";
      }
    });

    formAgain.addEventListener("click", () => {
      formSuccess.style.display = "none";
      form.style.display = "block";
    });
  }
});
