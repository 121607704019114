import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
Swiper.use([Navigation, Pagination]);
new Swiper(".team-slider", {
  spaceBetween: 10,
  breakpoints: {
    1720: {
      slidesPerView: 6,
    },
    1300: {
      slidesPerView: 4,
    },
    991: {
      slidesPerView: 3.2,
    },
    576: {
      slidesPerView: 2,
    },
    320: {
      slidesPerView: 1.5,
    },
  },
});

new Swiper(".reviews-slider", {
  spaceBetween: 10,
  // loop: true,
  breakpoints: {
    1720: {
      slidesPerView: 2.5,
    },
    1300: {
      slidesPerView: 2,
    },
    991: {
      slidesPerView: 1.5,
    },
    767: {
      slidesPerView: 1.5,
    },
    320: {
      slidesPerView: 1,
      autoHeight: true,
    },
  },
  navigation: {
    nextEl: ".reviews-slider__next",
    prevEl: ".reviews-slider__prev",
  },
});
